.btm {
  margin-bottom: 20px;
}
.theme-col {
  background-color: #b11f24;
}
.wht-txt {
  color: white;
}
#user-dp {
  height: 40px;
}
.user-info li {
  margin: 10px;
}
.dummy-component {
  background-color: yellow;
  border-radius: 20%;
}
.nav-list li {
  margin-left: 20%;
}
.nav-list li span {
  font-size: 20px;
}
.card img {
  height: 100px;
}
.card:hover {
  box-shadow: 2px 5px 10px 0px darksalmon;
}
.badge {
  /* height: 50px; */
  text-align: center;
  width: 10vw;
}

.card-row {
  margin-top: 50px;
}

.col-sm-6 {
  margin-top: 30px;
}
