.navbar{
    background-color: #B11F24;
    
}
@media (max-width: 768px) {
  .lang-selector {
    margin-top: 0.5rem;
  }
}

.blink-text {
  animation: blinker 3.5s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.danger-text {
  color: #b11f24;
}

.label-text {
  color: #de640f;
}

.red-text {
  color: #b11f24;
}

.green-text {
  color: #019443;
}

.yellow-text {
  color: #f5ab27;
}

.orange-text {
  color: #de640f;
}

.golden-text {
  color: #e3af32;
}

.black-text {
  color: #000;
}


.navitems{
    font-size:1rem;
    font-family:"Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
    color:#fff !important;
    font-weight: 500;
}
.nav-spacing{
  margin-left: 1rem;
  margin-right: 1rem;
}

.nav-space-left{
  margin-right: 1rem;
}
.navbar-light .navbar-nav .nav-link {
  color: #f8f9fa !important;
}
.navbaricon{
  display: none;
}

@media (max-width: 900px) {
  .lang-selector {
    margin-top: 0.5rem;

  }
}

.blink-text {
  animation: blinker 3.5s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}


@media only screen and (max-width: 767px) {
    /* .navbar {
      background-color: none;  
    } */
    .expanded-container{
      width: 97vw;
      overflow-x: scroll;
      white-space: nowrap;
    }
    .navbar {
      padding-top: 0 !important;
    padding-bottom: 0 !important; 
    }
    .navbaricon{
      display: block;
    }
    .header-toggler-icon {
      position: absolute;
      width: 1rem;
      height: 2px;
      color: inherit;
      background: currentColor;
      border-radius: 3px;
      top: 50%;
      left: 50%;
      margin: 2px 0 0 -.5rem;
      box-shadow: 0 5px currentColor, 0 -5px currentColor;
    }

    .header-toggler {
      width: 2rem;
      height: 2rem;
      position: relative;
      color: #e3af32 !important;
    }
    
    .header-toggler:hover {
      color: #6e7687;
    }
    .vertical-timeline::before{
      background: #000000!important;
    }
  }

  .react-bs-table-csv-btn{
    border:1px solid #495057 !important;
  }

  
  .react-bootstrap-table table {
    table-layout: auto !important;
    }

  .complaints-title{
    text-align: left;
    font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2.5rem;
    margin: 1rem;
  }
  .recent-complaints-title{
    text-align: left;
    font-family: "Source Sans Pro", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", Arial, sans-serif;
    font-size: 1.3rem;
    font-weight: 400;
    line-height: 2.5rem;
  }
  .page-title {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 400;
    line-height: 2.5rem;
  }
  .background{
    background-color: #F4F6F8
  }
  .notification-info-panel .notification-message .content {
    font-size: 15px !important;
  }
  .content ul {
    list-style: disc outside;
    margin-left: 0 !important;
    margin-top: 1em;
}

.notification-info-panel {
  max-height: 68vh !important;
;
  overflow-y: auto;
  padding: 0;
}

.popover{
  width:65vh !important;
  height:80vh !important;
  max-width:90vh !important;
}

.notification-info-panel .notification-message.unread {
  background-color: #ffb700 !important;
  color: #fff;
}

  .cardscrollable{
    overflow-y: scroll;
  }

  .table-background{
    background-color: #fff !important;
  }

  .export-button{
    margin: 0.5rem;
    align-items: right !important;
    float: right;
    padding-top: 0.2rem;
  }

  .align-left{
    align-content: "left" !important;
  }

  .set-left{
    align-items: left !important; 
  }

  .file-label {
    display: block;
    margin-bottom: .375rem;
    font-weight: 600;
    font-size: 1.15rem;
  }

  .react-bs-table-csv-btn{
    text-align: right !important;
  }

  .react-bootstrap-table-page-btns-ul{
    margin-right: 1rem;
  }

  .mar{
    margin-bottom: 5rem;
  }

.table-background-2{
  background-color:#fff;
}

.territoriescontainer {
  display: grid;
  flex-wrap: wrap;
  grid-template-columns: repeat(auto-fit, 200px);
  grid-row-gap: 10px;
  grid-column-gap: 20px;
  justify-content: center;
  width: 90%;
  margin: 0 auto;
  list-style: none;
}

.territoriescontainer>.item {
  height: 100%;
  flex: 1 1 0;
  min-width: 0;
  flex-wrap: wrap;
  margin: 4px;
}

.bootstrap-table-2{
  padding-top: 3rem !important;
  padding-bottom: 1000px;
}

.margin-table-1{
  margin:1rem;
}

.cardstyles{
  height: 80% !important;
};
.tab {
  width: 100%;
  padding: 25px;
  font-family: sans-serif;
  color: #444;
}


.dzu-previewButton {
  background-size: 14px 14px;
  background-position: center;
  background-repeat: no-repeat;
  width: 60px;
  height: 60px;
  cursor: pointer;
  opacity: 0.9;
  margin: 0 0 2px 10px;
}
#wrapper{
  overflow:hidden;
  width:100%;
}
.select-size{
  max-width: 185px;
}

.vertical-timeline::before {
  content: '';
  position: absolute;
  top: 0;
  left: 18px;
  height: 100%;
  width: 4px;
  background: #212529 !important;
}

.my-modal {
  width: 90vw;
  max-width: 90vw;
} 

.mobile-hide-button {
  display: none;
}

@media (max-width: 767px) {
  .mobile-hide-button {
    display: none; 
    .container {
      width: 90vw;
    }
  }
}


.nav-item {
  display: inline-block;
  margin-right: none; 
}


